import firstBenefit from "@images/benefit1.svg"
import thirdBenefit from "@images/benefit3.svg"
import hat from "@images/hat.svg"
import tax from "@images/tax.svg"
import golocker from "@images/goLocker-logo.png"
import lulu from "@images/lulu.png"

export const HEADER_BACKGROUND_IMAGE =
  "https://files.impressit.io/isp_7967d88913.png"
export const HEADER_DESCRIPTION =
  "We specialize in network infrastructure, performance monitoring, customer account management, customer apps, support tools, and marketing/sales, including website design and development."
export const HEADER_TITLE = "Custom software development for Internet Service Providers (ISP)"
export const HEADER_BUTTON_TEXT = "Let's chat"
export const MAIN_OFFERS_PRESENTATION_SECTION_TITLE =
  "Services for ISP"
export const MAIN_OFFERS_PRESENTATION_SECTION_DESCRIPTION =
  "We offer the following services for ISP:"

export const FAQ_SECTION_ITEMS = [
  {
    title: "What does ISP mean?",
    text: "ISP stands for Internet Service Provider It is a company or organization that provides Internet access to customers. ISPs typically offer a range of Internet connection types, such as dial-up, broadband, DSL, cable, or fiber optic, as well as related services such as email, web hosting, and virtual private networks (VPNs). Customers typically pay a monthly fee for their Internet service, and may also be subject to usage limits, bandwidth throttling, and other restrictions depending on their ISP and service plan.",
  },
  {
    title: "What is ISP software?",
    text: "ISP software refers to software that is used by Internet Service Providers (ISPs) to manage and maintain their networks and services. This software typically includes a range of tools for managing customer accounts, monitoring network performance, and providing various Internet-related services. Some common features of ISP software include: 1. Customer management: ISP software allows providers to manage customer accounts, including billing, service plans, and account information. 2. Network monitoring and management: ISP software enables providers to monitor their network performance, troubleshoot issues, and optimize performance. 3. Traffic management: ISPs can use software to manage and prioritize network traffic to ensure that all customers receive the necessary bandwidth. 4. Security: ISPs can use software to monitor and protect their networks against security threats such as malware, viruses, and other malicious activity. 5. Provisioning: ISPs can use software to manage the provisioning of new customers, including account setup, activation, and configuration of services. Overall, ISP software is essential for managing the complex networks and services provided by ISPs and ensuring that customers receive high-quality Internet access and related services.",
  },
]

export const FEEDBACK_SECTION_ITEMS = [
  {
    member: "Ross Ducat",
    position: "Founder & CEO, Lulu Light",
    logoSrc: lulu,
    feedbackText: "Their speed and knowledge were impressive",
  },
  {
    member: "Sherin Davis",
    position: "Chief Product Officer, GoLocker",
    logoSrc: golocker,
    feedbackText: "Overall, they are great partners",
  },
]

export const MAIN_OFFERS_PRESENTATION_SECTION_ITEMS = [
  {
    title: "Dedicated developers for ISP",
    text: "With our dedicated teams, you can benefit from the expertise of professionals who are well-versed in the latest technologies. Our teams offer the flexibility to rapidly adapt to market needs and can help you develop custom software solutions to improve overall performance.",
  },
  {
    title: "Custom software development for ISP",
    text: "Custom software development for internet service providers provides tailored solutions that streamline processes, boost efficiency, and adapt to changing market trends. This creates differentiation, strategic advantage, and better customer experiences, ultimately driving long-term success by achieving business goals. Our software development services are designed to meet the unique needs of ISPs, helping them to stay competitive in a rapidly evolving industry.",
  },
]

export const MAIN_OFFERS_PRESENTATION_SECTION_UNDERSECTION_TEXT =
  "Choose your service for outsourcing"

export const MAIN_OFFERS_PRESENTATION_SECTION_BUTTON_TEXT = "Let's talk"

export const INLINE_MAIN_OFFERS_PRESENTATION_SECTION_TITLE =
  "We can help you with:"

export const INLINE_MAIN_OFFERS_PRESENTATION_SECTION_ITEMS = [
  {
    title: "Network management software",
    text: "Our Network management software development is the key to effectively managing your network operations. We create custom software that helps you easily monitor network activity, identify and troubleshoot issues, and optimize network performance. We provide a comprehensive set of tools that enable you to efficiently manage your network, reducing downtime and increasing productivity.",
  },
  {
    title: "Billing and provisioning software",
    text: "We create custom billing and provisioning software for ISP. With our software, you can easily manage your customer accounts, create invoices, and process payments. Our solution can provide a user-friendly interface that simplifies complex billing and provisioning workflows, saving time and increasing accuracy.",
  },
  {
    title: "Customer support software",
    text: "Our experience in creating customer support software enhanced solutions for efficiently tracking and resolving customer issues and inquiries, and ensuring that your customers are satisfied with the level of service they receive. Our solution provides a range of tools to streamline your customer support process, including ticket management, chat support, and a knowledge base.",
  },
  {
    title: "Security software",
    text: "The Impressit team has experience in building security software to safeguard your network and data from unauthorized access and malicious attacks. We work closely with our clients to develop a customized security solution that meets their specific security needs.",
  },
  {
    title: "Traffic management software",
    text: "Our solutions for traffic management optimize network traffic and ensures that bandwidth is efficiently utilized. With custom software, you can prioritize network traffic, manage bandwidth usage, and reduce network congestion. We provide a user-friendly interface that enables you to easily monitor and manage network traffic, improving network performance and reducing downtime.",
  },
  {
    title: "DNS software",
    text: "Use DNS software for fast and reliable resolution of domain names, ensuring that your users can access your services without interruption. With special software, you can manage your domain names, configure DNS settings, and monitor DNS traffic. The Impressit team provides a range of tools to streamline your DNS management process, reducing downtime and increasing productivity.",
  },
  {
    title: "AI-driven transformation",
    text: "From predictive maintenance and network optimization to enhanced customer support, our AI solutions are the driving force behind operational excellence. Discover how AI can transform your ISP business, ensuring efficient network performance and superior customer satisfaction, ultimately positioning you for industry leadership and innovation.",
  },
]

export const CASE_STUDIES = [
  {
    id: "Case-studies_3",
    logo: { url: "https://files.impressit.io/gnetwork_logo_f8afe9daf5.svg" },
    metaUrl: "gnetwork",
    title:
      "Business automation system for the fastest growing networking company in London",
    wideImage: { url: "https://files.impressit.io/3gnetwork_4774e7179b.svg" },
  },
  {
    id: "Case-studies_34",
    logo: { url: "https://files.impressit.io/freedom_fibre_logo_9b23d6eb5f.svg" },
    metaUrl: "freedom-fibre",
    title:
      "Automation solutions development for a fast-growing UK broadband company",
    wideImage: { url: "https://files.impressit.io/freedom_fibre_wide2_8ad15c6658.png" },
  },
]

export const WHY_SCHOOSE_SECTION_TITLE = "Why choose Impressit?";

export const WHY_CHOOSE_ITEMS = [
  {
    title: "Full commitment",
    iconFromMarkupPath: firstBenefit,
    description:
      "At Impressit, we are equally dedicated to the success of your business and committed to supporting you throughout the entire development process.",
  },
  {
    title: "Time & costs savings",
    iconFromMarkupPath: tax,
    description:
      "Partnering with Impressit for quality development is crucial in avoiding future issues. Our experienced developers create top-notch products with comprehensive maintenance support for the lifetime of your software. Don't settle for less; prioritize security, functionality, and up-to-date technology with Impressit.",
  },
  {
    title: "Reduced Risks",
    iconFromMarkupPath: thirdBenefit,
    description:
      "While it may be tempting to hire freelance developers who offer lower hourly rates, it could end up costing you more in terms of security concerns, incomplete documentation, and subpar code.",
  },
  {
    title: "Experience",
    iconFromMarkupPath: hat,
    description:
      "It is a top priority for us to ensure that you are updated on all aspects of your software. We are delighted to provide our experience and insight to support you as you go through the production journey.",
  },
]

export const SECOND_CATCH_UP_SECTION_TITLE = "Get in touch";

export const SECOND_CATCH_UP_SECTION_DESCRIPTION = "Leave us a note and we will contact you within 24 hours";